<template>
<div class="typeOfCargoList" >

  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Тип груза</h5>
      <a @click="createItem">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
    <table class="table table-striped" id="typeOfCargoList">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Фото</th>
          <th scope="col">Наименование</th>
          <th scope="col">Порядок</th>
          <th scope="col">Тип</th>
          <th scope="col">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data">
          <th scope="row">{{index+1}}</th>
          <td><div class="my-img-circle"> <img v-if="value.image" class="img-fluid" :src="$store.state.urlBackend + '/' + value.image" alt=""> <img class="img-fluid" v-else width="100" :src="$store.state.noPhoto" alt=""> </div></td>
          <td>{{value.title}}</td>
          <td>{{value.order}}</td>
          <td v-if="!value.islocal"> <span class="btn btn-success">Международный</span> </td> <td v-else> <span class="btn btn-info">Местный</span> </td>
          <td>
            <a  @click="showItem(value)">
              <span class="border rounded border-primary m-1 p-2 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </span>
            </a>
            <a @click="editItem(value)">
              <span class="border rounded border-success m-1 p-2 text-success">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </span>
            </a>
            <a @click="deleteItem(index, value)">
              <span class="border rounded border-danger m-1 p-2 text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show Modal -->
<a-modal v-model="visibleShowModal" title="Детали"  footer="">
  <table class="table table-striped" >
    <tbody>
      <tr>
        <td>ID</td>
        <td>{{item.id}}</td>
      </tr>

      <tr>
        <td>Наименование</td>
        <td>{{item.title}}</td>
      </tr>
      <tr>
        <td>Порядок</td>
        <td>{{item.order}}</td>
      </tr>
      <tr>
        <td>Тип</td>
        <td v-if="item.islocal"><span class="btn btn-success">Местный</span></td> <td v-else><span class="btn btn-danger">Международный</span></td>
      </tr>
      <tr>
        <td>Длина</td>
        <td>{{item.length}} <span v-if="item.unit_id == 1">см</span> <span v-else>дюйм</span> </td>
      </tr>
      <tr>
        <td>Ширина</td>
        <td>{{item.width}} <span v-if="item.unit_id == 1">см</span> <span v-else>дюйм</span> </td>
      </tr>
      <tr>
        <td>Высота</td>
        <td>{{item.height}} <span v-if="item.unit_id == 1">см</span> <span v-else>дюйм</span> </td>
      </tr>
      <tr>
        <td>Вес</td>
        <td>{{item.weight}} <span v-if="item.unit_id == 1">см</span> <span v-else>дюйм</span> </td>
      </tr>
      <tr>
        <td>Единица измерения</td>
        <td v-if="item.unit">{{item.unit.title}}</td> <td v-else>--</td>
      </tr>

      <tr>
        <td>Фото</td>
        <td><img v-if="item.image" class="img-fluid" :src="$store.state.urlBackend + '/' + item.image" alt=""> <img class="img-fluid" v-else width="100" :src="$store.state.noPhoto" alt=""></td>
      </tr>
    </tbody>
  </table>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="item.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Порядок</label>
    <input type="text" class="form-control" v-model="item.order" :class="{'is-invalid': validateErrors.order}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.order"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Тип</label>
    <div class="col-sm-12">
      <a-radio-group v-model="item.islocal" @change="">
        <a-radio :style="" :value="1">
          Местный
        </a-radio>
        <a-radio :style="" :value="0">
          Зарубежный
        </a-radio>
    </a-radio-group>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.islocal"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Единица измерения</label>
    <div class="col-sm-12">
      <a-radio-group v-model="item.unit_id" @change="">
        <a-radio v-for="unit in units" :style="" :value="unit.id" >
          {{unit.title}}
        </a-radio>
    </a-radio-group>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.islocal"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Длина</label>
    <input type="text" class="form-control" v-model="item.length" :class="{'is-invalid': validateErrors.length}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.length"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Ширина</label>
    <input type="text" class="form-control" v-model="item.width" :class="{'is-invalid': validateErrors.width}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.width"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Высота</label>
    <input type="text" class="form-control" v-model="item.height" :class="{'is-invalid': validateErrors.height}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.height"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Вес</label>
    <input type="text" class="form-control" v-model="item.weight" :class="{'is-invalid': validateErrors.weight}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.weight"> {{value}} </div>
  </div>
  <div class="clearfix" :class="{'is-invalid': validateErrors.image}">
     <a-upload
       :action="$store.state.urlBackend + '/api/typeofcargo/image/upload/' + item.id"
       :headers='{"Authorization": `Bearer ` + access_token}'
       list-type="picture-card"
       :file-list="fileList"
       :before-upload="beforeUpload"
       @preview="handlePreview"
       @change="imageUploadFunction"
     >
       <div v-if="fileList.length < 1">
         <a-icon type="plus" />
         <div class="ant-upload-text">
           Upload
         </div>
       </div>
     </a-upload>
     <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
       <img alt="example" style="width: 100%" :src="previewImage" />
     </a-modal>
   </div>
   <div class="invalid-feedback" v-for="value in validateErrors.image"> {{value}} </div>

  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Изменить</button>
  </div>
</a-modal>

<!-- Create Modal -->
<a-modal v-model="visibleCreateModal" title="Добавить новую"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Порядок</label>
    <input type="text" class="form-control" v-model="newItem.order" :class="{'is-invalid': validateErrors.order}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.order"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Тип</label>
    <div class="col-sm-12">
      <a-radio-group v-model="newItem.islocal" @change="">
        <a-radio :style="" :value="1">
          Местный
        </a-radio>
        <a-radio :style="" :value="0">
          Зарубежный
        </a-radio>
    </a-radio-group>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.islocal"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Единица измерения</label>
    <div class="col-sm-12">
      <a-radio-group v-model="newItem.unit_id" @change="">
        <a-radio v-for="unit in units" :style="" :value="unit.id" >
          {{unit.title}}
        </a-radio>
    </a-radio-group>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.islocal"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Длина</label>
    <input type="text" class="form-control" v-model="newItem.length" :class="{'is-invalid': validateErrors.length}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.length"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Ширина</label>
    <input type="text" class="form-control" v-model="newItem.width" :class="{'is-invalid': validateErrors.width}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.width"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Высота</label>
    <input type="text" class="form-control" v-model="newItem.height" :class="{'is-invalid': validateErrors.height}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.height"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Вес</label>
    <input type="text" class="form-control" v-model="newItem.weight" :class="{'is-invalid': validateErrors.weight}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.weight"> {{value}} </div>
  </div>

  <div :class="{'is-invalid': validateErrors.image}">
    <div v-if="!file">
        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
          <div class="dropZone-info" @drag="onChange" >
            <span class="fa fa-cloud-upload dropZone-title"></span>
            <span class="dropZone-title">Перетащите файл сюда</span>
            <div class="dropZone-upload-limit-info">
              <div>Поддерживаемые расширения: png, jpg, jpeg, bmp</div>
              <div>Максимальный размер изображения: 5МБ</div>
            </div>
          </div>
          <input type="file" @change="onChange">
        </div>
      </div>
      <div v-else class="dropZone-uploaded">
        <div class="dropZone-uploaded-info">
          <span class="dropZone-title">Uploaded</span>
          <button type="button" class="btn btn-primary removeFile" @click="removeFile">Remove File</button>
        </div>
      </div>
  </div>

  <div class="invalid-feedback" v-for="value in validateErrors.image"> {{value}} </div>
  <br>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const data = '';
export default {
  name : 'TypeOfCargo',
  data(){
    return {
      data,
      units: [],
      file: '',
      dragging: false,
      previewVisible: false,
      previewImage: '',
      access_token: localStorage.token,
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],
      item: {
        title: '',
        order: '',
        islocal: 1,
        image: '',
        order: 1,
        unit_id: 1,
        length: 1,
        width: 1,
        height: 1,
        weight: 0.001
      },
      newItem: {
        title: '',
        order: '',
        islocal: 1,
        image: '',
        order: 1,
        unit_id: 1,
        length: 1,
        width: 1,
        height: 1,
        weight: 0.001
      },
      validateErrors: [],

      load: false,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,
      editCountryRegionCity: false,

      totalResults: 0,
      old_height: 0,
      last_page: 1,
      index: ''


    }
  },
  mounted(){
    this.load = true;
    axios.get('/typeofcargo').then(response => {
      if(response.status == 200){
        this.data = response.data.data;
        this.load = false;
        this.totalResults = response.data.total
        this.last_page = response.data.last_page
      }else if(response.status == 204){
        this.load = false;
        this.$message.error(
              'Тип груза  не найдено',
              3,
        );
      }
    }).catch(error => {
      this.load = false;
      this.$message.error(
            'Тип груза  не найдено',
            3,
      );
    });
    axios.get('/typeofcargo/get/units').then(response => {
      if(response.status == 200){
        this.units = response.data;
      }else if(response.status == 204){
        this.$message.error(
              'Единица измерения не найдены',
              5,
        );
      }
    }).catch(error => {
      this.$message.error(
            'Единица измерения не найдены',
            5,
      );
    });

    window.onscroll = () => {
        let height = document.getElementById('typeOfCargoList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
                this.load = true;
                axios.get('/typeofcargo', {params: {page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Тип груза не найдено',
                      3
                    );
                  }
                }).catch(error => {
                  if(error.response.status == 500){
                    this.load = false;
                    this.$message.error(
                      'Ошибка сервера',
                      3
                    );
                  }else if(error.response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Тип груза не найдено',
                      3
                    );
                  }else{
                    this.load = false
                  }
                });

            }
          }
        }
    };

  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
      // }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('Пожалуйста выберите (png, bmp, jpg, jpeg)!', 3);
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('Размер не должен превышать 2 мб!', 3);
      }
      return isJpgOrPng && isLt2M;
    },
    imageUploadFunction({ fileList }) {
      if(!fileList.length){
        axios.delete('/typeofcargo/image/delete/'+this.item.id).then(response => {
          if(response.status == 200){
            this.$message.success(response.data.success, 3);

            this.item.image = '';
          }else{
            this.$message.error(
              response.data.error,
              3
            );
          }
        }).catch(error => {
          console.log(error);
          if(error.response.status == 500){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.fileList = fileList;
        });
      }else{
        this.fileList = fileList;
        if(fileList[0].response){
          this.item.image = fileList[0].response
        }
        // let formData = new FormData();
        // formData.append('image', fileList);
        // console.log(formData);
        // axios.post('/typeofcargo/image/upload/' + this.item.id, formData, {
        //   headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': `multipart/form-data`,
        //   }})
        // .then(response => {
        //   console.log(response)
        //   if(response.status == 201){
        //     this.$message.success(
        //       'Успешно изменено',
        //       3,
        //     );
        //   }
        // }).catch(error => {
        //   if(error.response.status == 500){
        //     this.$message.error(
        //       'Ошибка сервера',
        //       3
        //     );
        //   }else if(error.response.status == 422){
        //     this.validateErrors = error.response.data.errors
        //     this.$message.error(
        //       error.response.data.message,
        //       3
        //     )
        //   }
        // });
      }

    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('please select txt file');
        this.dragging = false;
        return;
      }
      if (file.size > 2048000) {
        this.$message.error(
          'Пожалуйста выберите файла размером меньше 2МБ',
          3
        )
        this.dragging = false;
        return;
      }
      this.file = file;
      this.newItem.image = file;
      console.log(this.newItem.image);
      this.dragging = false;
    },
    removeFile() {
      this.file = '';
    },




    showItem(value){
      this.item = value;
      this.visibleShowModal = true;
    },
    editItem(value){
      this.fileList = [];
      if(value.image){
        let imageItem = [];
        imageItem.uid = value.id;
        imageItem.name = 'image.png';
        imageItem.status = 'done';
        imageItem.url = this.$store.state.urlBackend + '/' + value.image;
        this.fileList[0] = imageItem;
      }
      this.item = value;
      this.validateErrors = [];
      this.visibleEditModal = true;
    },
    createItem(){
      this.validateErrors = [];
      this.visibleCreateModal = true;
    },
    deleteItem(index, value){
      this.index = index;
      this.item = value;
      this.visibleDeleteModal = true;
    },

    updateItem(){
      this.load = true;
      axios.put('/typeofcargo/'+this.item.id, this.item)
      .then(response => {
        if(response.status == 200){
          this.load = false;
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );
        }else if(response.status == 404){
          this.$message.error(
                response.data.error,
                3,
          );
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 422){
          this.load = false;
          this.$message.error(
                error.response.data.message,
                3,
          );
          this.validateErrors = error.response.data.errors
        }else if(error.response.status == 404){
          this.load = false;
          this.$message.error(
                error.response.data.error,
                3,
          );
          this.load = false;
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
          this.load = false;
        }
      });
    },
    storeItem(){
      let formData = new FormData();
      formData.append('image', this.file);
      formData.append('title', this.newItem.title);
      formData.append('order', this.newItem.order);
      formData.append('islocal', this.newItem.islocal);
      formData.append('unit_id', this.newItem.unit_id);
      formData.append('length', this.newItem.length);
      formData.append('width', this.newItem.width);
      formData.append('height', this.newItem.height);
      formData.append('weight', this.newItem.weight);
      this.load = true;
      axios.post('/typeofcargo', formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': `multipart/form-data`,
        }})
      .then(response => {
        console.log(response)
        if(response.status == 201){
          this.visibleCreateModal = false;
          this.load = false;
          this.newItem.title = '';

          this.$message.success(
                'Успешно добавлено',
                3,
          );
        }else{
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.load = false;
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
          this.load = false
          this.$message.error(
            error.response.data.message,
            3
          )
        }
      });
    },
    destroyItem(){
      axios.delete('/typeofcargo/'+this.item.id).then(response => {
         if(response.status==200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      }).finally(() => {
          this.visibleDeleteModal = false
      });
    },

  },
  computed: {
    extension() {
      return (this.file) ? this.file.name.split('.').pop() : '';
    }
  }
}
</script>
<style scoped>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9
  }


.dropZone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px dotted #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
